<template>
  <b-card>
    <baseform
      :fields="fields"
      :posturl="dataurl"
      :title="title"
      :set_redirect="true"
    />
  </b-card>
</template>

<script>
import baseform from '@/views/base/BaseFormV2.vue'
import {BCard} from 'bootstrap-vue'

export default {
  components: {
    baseform,
    BCard,
  },
  data() {
    return {   
      title:'Dokumen',
      dataurl:'/file-type',
      baseroute:'dokumen',
      fields:[
        { key: 'name', label: 'Nama', type: 'input', rules:'required' , placeholder:'Nama'},
        { key: 'type', label: 'Keperluan', type: 'radio', rules:'required', setting:{sub_a:{label: 'Dokumen SPB', value: 'spb'},sub_b:{label: 'Dokumen Kendaraan', value: 'vehicle'}}},
        { key: 'is_required', label: '', lbel: 'Wajib Ada', type: 'bool', rules:''},
        { key: 'related', label: 'Dokument Terkait', type: 'radio', 
          setting:{
            sub_a:{label: 'Tidak Terkait', value: null},
            sub_b:{label: 'BAPP', value: 'bapp'},
            sub_c:{label: 'BAST', value: 'bast'},
            sub_d:{label: 'RIKMATEK', value: 'rikmatek'},
            sub_e:{label: 'INVOICE', value: 'invoice'},
            sub_f:{label: 'KWITANSI', value: 'kwitansi'},
            sub_g:{label: 'SPB', value: 'spb'},
            sub_h:{label: 'SPP', value: 'spp'},
            sub_h:{label: 'KONTRAK', value: 'contract'},
            sub_i:{label: 'BA Denda', value: 'badk'},
          }
        },
        { key: 'all_invoice', label: '', lbel: 'Untuk semua tagihan', type: 'bool', rules:''},
      ]
    }
  },
}
</script>